<template>
  <disclosure-container>
    <template #form="props">
      <h1 class="form__title">
        {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.TITLE') }}
      </h1>
      <form @submit.prevent="submitDisclosure(props)">
        <div>
          <label class="label" for="relationshipName">
            {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.RELATED_PERSON') }}
          </label>
          <input
            id="relatedPerson"
            v-model="disclosure.relationshipPersonName"
            type="text"
            class="text-input"
            name="relatedPerson"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
            autocomplete="off"
          />
        </div>

        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.RELATIONSHIP_TYPE') }}
          </p>
          <p class="description">
            {{
              $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.RELATIONSHIP_TYPE_HELP')
            }}
          </p>
          <md-radio
            id="family"
            v-model="disclosure.relationshipType"
            class="eco"
            value="FAMILY"
          >
            <label for="family">
              {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.FAMILY') }}
            </label>
          </md-radio>
          <br />
          <md-radio
            id="romantic"
            v-model="disclosure.relationshipType"
            class="eco"
            value="romantic"
          >
            <label for="romantic">
              {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.ROMANTIC') }}
            </label>
          </md-radio>
        </div>

        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.REPORTING_LINE') }}
          </p>
          <md-radio
            id="yes"
            v-model="disclosure.shareHierarchy"
            class="eco"
            :value="true"
          >
            <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
          </md-radio>
          <br />
          <md-radio
            id="no"
            v-model="disclosure.shareHierarchy"
            class="eco"
            :value="false"
          >
            <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
          </md-radio>
        </div>

        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.INSIDE_RELATIONSHIP.OVERLAP') }}
          </p>
          <md-radio
            id="yes"
            v-model="disclosure.shareResponsabilities"
            class="eco"
            :value="true"
          >
            <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
          </md-radio>
          <br />
          <md-radio
            id="no"
            v-model="disclosure.shareResponsabilities"
            class="eco"
            :value="false"
          >
            <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
          </md-radio>
        </div>

        <div>
          <p class="label">
            {{ $t('DISCLOSURE_FORMS.MANAGE_AWERENESS') }}
          </p>
          <md-radio
            id="yes"
            v-model="disclosure.isManagerAware"
            class="eco"
            :value="true"
          >
            <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
          </md-radio>
          <br />
          <md-radio
            id="no"
            v-model="disclosure.isManagerAware"
            class="eco"
            :value="false"
          >
            <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
          </md-radio>
        </div>
        <div>
          <p class="label">
            {{
              $t('DISCLOSURE_FORMS.PEOPLE_PARTNER', {
                companyName: company.name,
              })
            }}
          </p>
          <md-radio
            id="yes"
            v-model="disclosure.isHrAware"
            class="eco"
            :value="true"
          >
            <label for="yes">{{ $t('DISCLOSURE_FORMS.YES') }}</label>
          </md-radio>
          <br />
          <md-radio
            id="no"
            v-model="disclosure.isHrAware"
            class="eco"
            :value="false"
          >
            <label for="no">{{ $t('DISCLOSURE_FORMS.NO') }}</label>
          </md-radio>
        </div>
        <div>
          <label class="label" for="additionalComments">
            {{ $t('DISCLOSURE_FORMS.COMMENTS') }}
          </label>
          <textarea
            id="additionalComments"
            v-model="disclosure.observations"
            name="additionalComments"
            rows="5"
            :placeholder="$t('DISCLOSURE_FORMS.TYPE_HERE')"
          />
        </div>

        <div>
          <p class="description center">
            {{ $t('DISCLOSURE_FORMS.INFORMATIONS') }}
          </p>
          <s-button id="disclosure" type="submit" btn-style="eco large">
            {{ $t('DISCLOSURE_FORMS.SEND') }}
          </s-button>

          <p class="description-footer center">
            {{ $t('DISCLOSURE_FORMS.FOOTER_SAFESPACE') }}
          </p>
        </div>
      </form>
    </template>
  </disclosure-container>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Disclosures } from '@/domain/disclosure'
import DisclosureContainer from './DisclosureContainer'
import SButton from '@/components//ui/s-button'

import backIcon from '@/assets/images/issues/back_eco_icon.svg'

export default {
  name: 'InsideRelationship',

  components: {
    SButton,
    DisclosureContainer,
  },

  data() {
    return {
      disclosure: { ...Disclosures.InsideRelationship },
      backIcon,
    }
  },

  computed: mapState(['company']),

  methods: {
    ...mapActions(['createDisclosure']),

    async submitDisclosure(props) {
      const params = {
        ...props,
        ...this.disclosure,
      }

      const result = await this.createDisclosure(params)

      this.disclosure = { ...Disclosures.InsideRelationship }

      const { slug, slugId } = this.company
      this.$router.push({
        name: 'company-disclosure-success',
        params: { slug, slugId, agree: true, ...result },
      })
    },
  },
}
</script>
